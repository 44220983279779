import { ROUTES_PATHS } from './routes';

export const TICKETS_OPEN_URL = {
  CLASSIC: (srId) => `/SREdit.jsp?id=${srId}&openedInIframe=1`,
  SPACES: (srId) => `${ROUTES_PATHS.BASE_PATH}/ticket?id=${srId}`,
};

export const TICKET_EVENTS = {
  TICKET_CREATED: 'ticketCreated',
  TICKET_CREATED_TOAST_CLICKED: 'ticketCreatedToastClicked',
  TICKET_ARCHIVED: 'ticketArchived',
};
