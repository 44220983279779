import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import rtlPlugin from 'stylis-plugin-rtl';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { routeConfig } from 'routes';
import { PendoGuideManager } from 'common/components/pendo/PendoGuideManager';
import { selectDirection, selectNavigationObject, setNavigationObject } from 'store/globalSlice';
import usePreviousValue from 'common/utils/hooks/usePreviousValue';
import { useMemoryReporting } from 'common/utils/hooks/useMemoryUsage';
import { handlePreventXssAttack, isWindows, removeCookie } from 'common/utils/utils';
import { APP_CONSTANTS } from 'constants/index';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import useInitializeActivePopups from 'common/utils/hooks/useInitializeActivePopups';
import { GlobalStyle } from './global';
import { StyledWrapper } from './style';
import { useKeepAlive } from './hooks/useKeepAlive';
import { useFeatureFlagQuery } from '../../remote-state/featureFlagsHooks';
import { FEATURE_FLAGS_KEYS } from '../../constants/featureFlags';
import { useContextQuery } from '../../remote-state/platformIntegrationServiceHooks';
import FeatureFlagMonitor from '../../FeatureFlagMonitor';

export default function App() {
  const dispatch = useDispatch();
  useKeepAlive();
  const navigationObject = useSelector(selectNavigationObject);
  const router = useMemo(() => createRouter({ routeTree: routeConfig() }), []);
  const ldProviderOptions = useMemo(() => ({ streaming: process.env.NODE_ENV !== 'development' }), []);
  const prevNavigationObject = usePreviousValue(navigationObject);
  const { data: isRTLEnabled } = useFeatureFlagQuery({
    flagKey: FEATURE_FLAGS_KEYS.RTL_ENABLED,
    defaultValue: false,
  });
  const direction = useSelector(selectDirection);
  const isRTL = direction === 'rtl' && isRTLEnabled;
  const handleBeforeUnload = useCallback(() => {
    removeCookie(APP_CONSTANTS.IN_SPACES_IFRAME_COOKIE);
  }, []);

  useEffect(() => {
    handlePreventXssAttack(router);
  }, [router]);

  const { data: context = undefined } = useContextQuery();

  useInitializeActivePopups();

  const normalizedContext = useMemo(() => {
    if (context) return { kind: 'user', name: context.account, key: context.account, ...context };
    return null;
  }, [context]);

  useEffect(() => {
    if (navigationObject && prevNavigationObject !== navigationObject) {
      if (navigationObject.to && navigationObject.forceWindow) {
        window.location.href = navigationObject.to;
      } else {
        router.navigate(navigationObject);
      }
      dispatch(setNavigationObject(null));
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigationObject, prevNavigationObject, router, dispatch, handleBeforeUnload]);

  useMemoryReporting(60000);

  const rtlCache = createCache({
    key: 'cssrtl',
    stylisPlugins: [rtlPlugin],
  });
  const ltrCache = createCache({
    key: 'css',
  });

  return (
    <LDProvider
      clientSideID={process.env.REACT_APP_LD_CLIENT_KEY}
      deferInitialization
      context={normalizedContext}
      options={ldProviderOptions}
    >
      {process.env.NODE_ENV !== 'development' && <FeatureFlagMonitor />}
      <StyledWrapper>
        <GlobalStyle isWindows={isWindows} />
        <CacheProvider value={isRTL ? rtlCache : ltrCache}>
          <RouterProvider router={router}>
            <PendoGuideManager />
          </RouterProvider>
        </CacheProvider>
      </StyledWrapper>
    </LDProvider>
  );
}
