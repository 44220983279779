const BASE_PATH = '/spaces';
enum Page {
  HOME = '',
  LOGOUT = 'logout',
  TICKET = 'ticket',
  TEMPLATES_LIST = 'templates-list',
  SETTINGS = 'settings',
  CLASSIC = 'classic',
  CONNECT = 'connect',
};

export const FORGOT_PASSWORD_SUBDIRECTORY = 'forgot-password';
export const LOGIN_SUBDIRECTORY = 'login';
export const ROUTES_PATHS = {
  BASE_PATH,
  QUEUE: `${BASE_PATH}/`,
  LOGIN_URL: `${BASE_PATH}/${LOGIN_SUBDIRECTORY}`,
  LOGOUT_URL: `${BASE_PATH}/${Page.LOGOUT}`,
  TEMPLATE_URL: `${BASE_PATH}/template`,
  TEMPLATE_LIST_URL: `${BASE_PATH}/${Page.TEMPLATES_LIST}`,
  SETTINGS_URL: `${BASE_PATH}/${Page.SETTINGS}`,
  TICKET_URL: `${BASE_PATH}/${Page.TICKET}`,
  FORGOT_PASSWORD_URL: `${BASE_PATH}/${FORGOT_PASSWORD_SUBDIRECTORY}`,
  CHECKMAIL_URL: `${BASE_PATH}/check-mail`,
  EXTERNAl_PAGES_URL: `${BASE_PATH}/${Page.CLASSIC}`,
  PAGE404_URL: `${BASE_PATH}/404`,
  _URL: `${BASE_PATH}/template`,
  SERVICE_PORTAL_URL: '/servicePortal',
  WORKFLOW_DESIGNER: '/workflowDesigner',
  KEEP_ALIVE: '/KeepAlive.jsp',
  PREFERENCES: '/Preferences.jsp',
  CONNECT: `${BASE_PATH}/${Page.CONNECT}`,
};

export const PAGES_LIST = Object.values(Page);
