export const QUERIES_KEYS = {
  CURRENT_USER: 'currentUser',
  CURRENT_USER_FROM_CORE: 'currentUserFromCore',
  USER_DATA: 'userData',
  CURRENT_USER_PERMISSIONS: 'currentUserPermissions',
  USER_CONF: 'userConf',
  PHOTO: 'photo',
  LOGIN_OBJECT: 'loginObject',
  RESOURCE_BUNDLE: 'resourceBundle',
  APPLICATION_DATA: 'applicationData',
  GENERAL_SETTINGS: 'generalSettings',
  IS_EXTERNAL_MODE: 'isExternalMode',
  ADMINISTRATORS: 'administrators',
  BULK_ACTIONS_LIMIT: 'bulkActionsLimit',
  EDITION: 'edition',
  GROUPS: 'groups',
  MENU_ITEMS: 'menuItems',
  USER_DETAILS_BY_NAME: 'userDetailsByName',
  USER_DETAILS_BY_ID: 'userDetailsById',
  IS_AI_ENABLED: 'isAiEnabled',
  ASSISTIVE_AI: 'assistiveAi',
  IS_AI_AUTHOR: 'AIAuthor',
  IS_AI_EMOTIONS: 'AIEmotions',
  IS_AI_AUTO_POPULATE: 'AIAutoPopulate',
  IS_AI_SUGGESTED_CATEGORY: 'AISuggestedCategory',
  IS_AI_SUMMARIZATIONS: 'AICaseSummarizations',
  IS_AI_ADMINISTRATOR: 'isAiAdministrator',
  ENABLED_SWITCH_UI: 'enabledSwitchUi',
  DEFAULT_UI: 'defaultUi',
  FIELD_ATTRIBUTE: 'fieldAttribute',
  ATTRIBUTES: 'attributes',
  USER_ATTRIBUTES: 'userAttributes',
  TEMPLATE_DATA: 'templateData',
  SR_ID: 'srId',
  AI_CONFIG_DATA: 'AIConfigData',
  KEEP_ALIVE: 'keepAlive',
  STATUS_SETTINGS: 'statusSettings',
  TEMPLATE_DESIGNER_AVAILABLE_FIELDS: 'templateDesignerAvailableFields',
  COLUMN_ORDER_AVAILABLE_FIELDS: 'columnOrderAvailableFields',
  TEMPLATES_LIST: 'templatesList',
  DEFAULT_TEMPLATE: 'defaultTemplate',
  TEMPLATES_LIST_TOTAL_SIZE: 'templatesListTotalSize',
  TEMPLATE_BY_SR_TYPE: 'templateBySrType',
  TICKET_PERMISSIONS: 'ticketPermissions',
  LICENS_ECHECK: 'licenseCheck',
  ASSOCIATED_FIELDS: 'associatedFields',
  QUEUE_DATA: 'queueData',
  CLOSURE_INFORMATION: 'closureInformation',
  TICKET_LOCK_STATUS: 'ticketLockStatus',
  STATUS_LIST: 'statusList',
  CATEGORIES_ATTR: 'categoriesAttr',
  JIRA_PROJECTS: 'jiraProjects',
  JIRA_ISSUES: 'jiraIssues',
  JIRA_ASSIGNEES: 'jiraAssignees',
  JIRA_ISSUE_DETAILS: 'jiraIssueDetails',
  SR_ATTACHMENTS: 'srAttachments',
};

export const QUERY_STALE_TIME = {
  NONE: 0,
  MINUTE: 1000 * 60 * 1,
  SHORT: 1000 * 60 * 2,
  DEFAULT: 1000 * 60 * 5,
  MEDIUM: 1000 * 60 * 15,
  LONG: 1000 * 60 * 60 * 24,
};

export const AUDIT_LOG_IMAGE_DOMAIN = '/spaces/api';

export const queryEndPoints = {
  ATTACHMENTS: `${AUDIT_LOG_IMAGE_DOMAIN}/attachments`,
};
