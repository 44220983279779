export enum TRACK_EVENTS {
  // AI-related events
  SPACES_AI_REPHRASE = 'SPACES_AI_REPHRASE',
  SPACES_AI_TICKET_SUMMARY_AND_SENTIMENT_DISPLAYED = 'SPACES_AI_TICKET_SUMMARY_AND_SENTIMENT_DISPLAYED',
  SPACES_AI_TICKET_SUMMARY_ERROR = 'SPACES_AI_TICKET_SUMMARY_ERROR',
  SPACES_AI_SUGGESTED_CATEGORY_CHECKED = 'SPACES_AI_SUGGESTED_CATEGORY_CHECKED',
  SPACES_AI_SUGGESTED_CATEGORY_SELECTED = 'SPACES_AI_SUGGESTED_CATEGORY_SELECTED',

  // Custom columns
  CUSTOM_COLUMN_ADD_TO_TEMPLATE = 'CUSTOM_COLUMN_ADD_TO_TEMPLATE',
  CUSTOM_COLUMN_REMOVE_FROM_TEMPLATE = 'CUSTOM_COLUMN_REMOVE_FROM_TEMPLATE',
  CUSTOM_COLUMN_POPULATED = 'CUSTOM_COLUMN_POPULATED',

  // Activities
  ACTIVITIES_CLICKED = 'ACTIVITIES_CLICKED',
  ACTIVITY_SUBMITTED = 'ACTIVITY_SUBMITTED',
  ACTIVITY_DELETED = 'ACTIVITY_DELETED',

  // Ticket actions
  TICKET_MISSING_REQUIRED_FIELDS = 'TICKET_MISSING_REQUIRED_FIELDS',
  SWITCH_TO_CLASSIC_FEEDBACK = 'SWITCH_TO_CLASSIC_FEEDBACK',
  ADD_NEW_COLUMN = 'ADD_NEW_COLUMN',
  DELETE_COLUMN = 'DELETE_COLUMN',
  OPEN_SR_FROM_QUEUE = 'OPEN_SR_FROM_QUEUE',
  DELETE_TICKET_WAS_CLICKED = 'DELETE_TICKET_WAS_CLICKED',

  // Template actions
  SET_TEMPLATE_AS_DEFAULT_WAS_CLICKED = 'SET_TEMPLATE_AS_DEFAULT_WAS_CLICKED',
  DUPLICATE_TEMPLATE_WAS_CLICKED = 'DUPLICATE_TEMPLATE_WAS_CLICKED',
  DELETE_TEMPLATE_WAS_CLICKED = 'DELETE_TEMPLATE_WAS_CLICKED',

  // UI interactions
  POPUP_LINK_CLICKED = 'POPUP_LINK_CLICKED',
  POPUP_BUTTON_CLICKED = 'POPUP_BUTTON_CLICKED',
  OPEN_CLASSIC_QUEUE_CLICK = 'OPEN_CLASSIC_QUEUE_CLICK',
  OPEN_IN_CLASSIC_FROM_QUEUE_CLICK = 'OPEN_IN_CLASSIC_FROM_QUEUE_CLICK',
  OPEN_IN_CLASSIC_FROM_TICKET_CLICK = 'OPEN_IN_CLASSIC_FROM_TICKET_CLICK',
  RESOURCE_CENTER_MENU_CLICK = 'RESOURCE_CENTER_MENU_CLICK',

  // Conversion events
  CONVERT_TO_REQUEST_QUEUE = 'CONVERT_TO_REQUEST_QUEUE',
  CONVERT_TO_REQUEST_TICKET_PAGE = 'CONVERT_TO_REQUEST_TICKET_PAGE',
  CONVERT_TO_INCIDENT_QUEUE = 'CONVERT_TO_INCIDENT_QUEUE',
  CONVERT_TO_INCIDENT_TICKET_PAGE = 'CONVERT_TO_INCIDENT_TICKET_PAGE',
  GO_BACK_BUTTON_CONVERSION_POPUP_CONVERT_TO_REQUEST = 'GO_BACK_BUTTON_CONVERSION_POPUP_CONVERT_TO_REQUEST',
  CONVERT_BUTTON_CONVERSION_POPUP_CONVERT_TO_REQUEST = 'CONVERT_BUTTON_CONVERSION_POPUP_CONVERT_TO_REQUEST',
  GO_BACK_BUTTON_CONVERSION_POPUP_CONVERT_TO_INCIDENT = 'GO_BACK_BUTTON_CONVERSION_POPUP_CONVERT_TO_INCIDENT',
  CONVERT_BUTTON_CONVERSION_POPUP_CONVERT_TO_INCIDENT = 'CONVERT_BUTTON_CONVERSION_POPUP_CONVERT_TO_INCIDENT',
  CONVERSION_SUCCEEDED = 'CONVERSION_SUCCEEDED',
  CONVERSION_FAILED = 'CONVERSION_FAILED',

  // Journey events
  EXPAND_ALL_JOURNEY_TOGGLE_ON = 'EXPAND_ALL_JOURNEY_TOGGLE_ON',
  EXPAND_ALL_JOURNEY_TOGGLE_OFF = 'EXPAND_ALL_JOURNEY_TOGGLE_OFF',
  SHOW_MORE_JOURNEY_MESSAGES = 'SHOW_MORE_JOURNEY_MESSAGES',
  SHOW_MORE_JOURNEY_NOTES = 'SHOW_MORE_JOURNEY_NOTES',

  // SR related events
  POPULATED_SR_CANCEL_CLICK = 'POPULATED_SR_CANCEL_CLICK',
  POPULATED_SR_CREATE_CLICK = 'POPULATED_SR_CREATE_CLICK',
  NEW_SR_I_PREFER_MANUAL_LABOR_CLICK = 'NEW_SR_I_PREFER_MANUAL_LABOR_CLICK',
  NEW_SR_AUTO_FILL_FOR_ME_CLICK = 'NEW_SR_AUTO_FILL_FOR_ME_CLICK',
  WARNING_POPUP_ABORT_MISSION_CLICK = 'WARNING_POPUP_ABORT_MISSION_CLICK',
  WARNING_POPUP_AUTO_FILL_FOR_ME_CLICK = 'WARNING_POPUP_AUTO_FILL_FOR_ME_CLICK',
  CHANGE_SR_TYPE_TO_INCIDENT_FROM_NEW_TICKET = 'CHANGE_SR_TYPE_TO_INCIDENT_FROM_NEW_TICKET',
  CHANGE_SR_TYPE_TO_REQUEST_FROM_NEW_TICKET = 'CHANGE_SR_TYPE_TO_REQUEST_FROM_NEW_TICKET',
  CHANGE_SR_TYPE_TO_PROBLEM_FROM_NEW_TICKET = 'CHANGE_SR_TYPE_TO_PROBLEM_FROM_NEW_TICKET',
  CHANGE_SR_TYPE_TO_CHANGE_FROM_NEW_TICKET = 'CHANGE_SR_TYPE_TO_CHANGE_FROM_NEW_TICKET',
  CREATE_NEW_SR_CREATION = 'CREATE_NEW_SR_CREATION',
  CANCEL_NEW_SR_CREATION = 'CANCEL_NEW_SR_CREATION',
  CHANGE_SR_TYPE_CHEVRON_CLICK_FROM_NEW_TICKET = 'CHANGE_SR_TYPE_CHEVRON_CLICK_FROM_NEW_TICKET',

  // Related items events
  FIRST_LINK_CLICK_IN_RELATED_ITEMS_EMPTY_STATE_TAB = 'FIRST_LINK_CLICK_IN_RELATED_ITEMS_EMPTY_STATE_TAB',
  CHOOSING_ENTITY_TYPE_IN_RELATED_ITEMS_BOX = 'CHOOSING_ENTITY_TYPE_IN_RELATED_ITEMS_BOX',
  CHOOSING_RELATIONSHIP_TYPE_IN_RELATED_ITEMS_BOX = 'CHOOSING_RELATIONSHIP_TYPE_IN_RELATED_ITEMS_BOX',
  LINK_NEW_RELATED_ITEM_CLICK = 'LINK_NEW_RELATED_ITEM_CLICK',
  CANCEL_NEW_RELATED_ITEM_LINK_CLICK = 'CANCEL_NEW_RELATED_ITEM_LINK_CLICK',
  UNLINK_EXISTING_RELATED_ITEM_ICON_CLICK = 'UNLINK_EXISTING_RELATED_ITEM_ICON_CLICK',
  UNLINK_EXISTING_RELATED_ITEM_BUTTON_CLICK_IN_POPUP = 'UNLINK_EXISTING_RELATED_ITEM_BUTTON_CLICK_IN_POPUP',
  CANCEL_UNLINKING_EXISTING_RELATED_ITEM_BUTTON_CLICK_IN_POPUP = 'CANCEL_UNLINKING_EXISTING_RELATED_ITEM_BUTTON_CLICK_IN_POPUP',
  PARENT_INDICATOR_CLICK_IN_TICKET_PAGE = 'PARENT_INDICATOR_CLICK_IN_TICKET_PAGE',
  CHILD_INDICATOR_CLICK_IN_TICKET_PAGE = 'CHILD_INDICATOR_CLICK_IN_TICKET_PAGE',

  // Archive events
  ARCHIVE_DONE_FROM_POPUP = 'ARCHIVE_DONE_FROM_POPUP',
  ARCHIVE_CANCELLED_FROM_POPUP = 'ARCHIVE_CANCELLED_FROM_POPUP',

  // Duplicate events
  DUPLICATE_ACTION_ITEM_CLICK = 'DUPLICATE_ACTION_ITEM_CLICK',
  DUPLICATE_DONE_FROM_POPUP = 'DUPLICATE_DONE_FROM_POPUP',
  DUPLICATE_CANCELLED_FROM_POPUP = 'DUPLICATE_CANCELLED_FROM_POPUP',
  // Merge events
  MERGE_CLICK_IN_BULK_ACTION_BAR = 'MERGE_CLICK_IN_BULK_ACTION_BAR',
  MERGE_CLICK_IN_SPECIFICATIONS_OVERLAY = 'MERGE_CLICK_IN_SPECIFICATIONS_OVERLAY',
  CANCEL_CLICK_IN_SPECIFICATIONS_OVERLAY = 'CANCEL_CLICK_IN_SPECIFICATIONS_OVERLAY',
  MERGE_ATTACHMENTS_IN_SPECIFICATIONS_OVERLAY = 'MERGE_ATTACHMENTS_IN_SPECIFICATIONS_OVERLAY',
  MERGE_MESSAGES_IN_SPECIFICATIONS_OVERLAY = 'MERGE_MESSAGES_IN_SPECIFICATIONS_OVERLAY',
  MERGE_NOTES_IN_SPECIFICATIONS_OVERLAY = 'MERGE_NOTES_IN_SPECIFICATIONS_OVERLAY',
  MERGE_ACTIVITIES_IN_SPECIFICATIONS_OVERLAY = 'MERGE_ACTIVITIES_IN_SPECIFICATIONS_OVERLAY',
  GOT_IT_CLICK_IN_MERGE_CONFIRMATION_POPUP = 'GOT_IT_CLICK_IN_MERGE_CONFIRMATION_POPUP',
  CANCEL_CLICK_IN_MERGE_CONFIRMATION_POPUP = 'CANCEL_CLICK_IN_MERGE_CONFIRMATION_POPUP',
  CHECK_IN_MERGE_CONFIRMATION_POPUP = 'CHECK_IN_MERGE_CONFIRMATION_POPUP',
};
